import { useEffect } from 'react'
import { useQuery } from '@tanstack/react-query'
import { getAds } from '@services/api/Ads'
import useSentry from '@hooks/useSentry'
import usePreviousState from '@hooks/usePreviousState'
import { CONTAINER } from '@services/api/Ads/constants'

interface UseQueryAdsProps {
	type: string
	isMobile?: boolean
}

const useQueryAds = ({ type, isMobile }: UseQueryAdsProps) => {
	const { setEvent } = useSentry()
	const { containerId, jwtToken } = CONTAINER[type][isMobile ? 'mo' : 'pc']

	const result = useQuery({
		queryKey: ['@ads', containerId],
		queryFn: () => getAds({ containerId, jwtToken }),
		refetchOnMount: 'always',
	})
	const { isSuccess, data } = result
	const isNoneAds = !isSuccess || !!data?.error
	const prevIsNoneAds = usePreviousState(isNoneAds)

	useEffect(() => {
		if (!prevIsNoneAds && isNoneAds) {
			setEvent({
				message: `[니트머스 광고] ${containerId} - 광고 표시되지 않음`,
				level: 'info',
			})
		}
	}, [isNoneAds, containerId])

	return {
		...result,
		isNoneAds,
	}
}

export default useQueryAds
