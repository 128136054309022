export const CONTAINER_IDS = {
	houseBanner: 'house-banner',
}

export const CONTAINER = {
	[CONTAINER_IDS.houseBanner]: {
		pc: {
			containerId: 'YzXPgssJTcKvR2BAbZ0XZQ',
			jwtToken:
				'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcHAiOnsibmFtZSI6IkpUQkNORVdTIiwiY29udGVudCI6eyJleHQiOnt9LCJjYXQiOlsiMDEiXSwibGl2ZXN0cmVhbSI6MH19LCJpZCI6IiIsImRldmljZSI6eyJvcyI6IkFORFJPSUQiLCJkZXZpY2V0eXBlIjoxLCJoIjowLCJtb2RlbCI6IkFuZHJvaWQiLCJpZmEiOiI4MjE3N2YxMy1jYWJmLTRmMTAtYTY1Yi0wZGZjNTZlYzhmMTQiLCJsbXQiOjEsInciOjAsImlwIjoiMTI3LjAuMC4xIn0sImltcCI6W3sibmF0aXZlIjp7ImV4dCI6eyJzbG90cyI6MX0sInZlciI6IjEuMCIsInJlcXVlc3QiOiIifX1dLCJ1c2VyIjp7InlvYiI6MCwiZXh0Ijp7ImFnZSI6OTl9LCJnZW50ZXIiOiJPIiwiY3VzdG9tZGF0YSI6Ijc3MUY0NDFCMkE3OUE2QjE2MDAyMkE2NzFDNDdCNTkyIn19.RG-sicFMfEpAni_XPWUPxQqqGEEMrne8Nxymby7riJM',
		},
		mo: {
			containerId: 'brPWoljARUKHvMOZ8Fdyzg',
			jwtToken:
				'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcHAiOnsibmFtZSI6IkpUQkNORVdTIiwiY29udGVudCI6eyJleHQiOnt9LCJjYXQiOlsiMDEiXSwibGl2ZXN0cmVhbSI6MH19LCJpZCI6IiIsImRldmljZSI6eyJvcyI6IkFORFJPSUQiLCJkZXZpY2V0eXBlIjoxLCJoIjowLCJtb2RlbCI6IkFuZHJvaWQiLCJpZmEiOiI4MjE3N2YxMy1jYWJmLTRmMTAtYTY1Yi0wZGZjNTZlYzhmMTQiLCJsbXQiOjEsInciOjAsImlwIjoiMTI3LjAuMC4xIn0sImltcCI6W3sibmF0aXZlIjp7ImV4dCI6eyJzbG90cyI6MX0sInZlciI6IjEuMCIsInJlcXVlc3QiOiIifX1dLCJ1c2VyIjp7InlvYiI6MCwiZXh0Ijp7ImFnZSI6OTl9LCJnZW50ZXIiOiJPIiwiY3VzdG9tZGF0YSI6Ijc3MUY0NDFCMkE3OUE2QjE2MDAyMkE2NzFDNDdCNTkyIn19.mjKuO93N9FBlnGah2j0UDVouvXUUXRY4XMOOSS2nFJQ',
		},
	},
}
