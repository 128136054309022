import * as Sentry from '@sentry/nextjs'
import type { Event } from '@sentry/nextjs'

const useSentry = () => {
	/** Sentry 커스텀 로그 이벤트 */
	const setEvent = (options: Event) => {
		const { message } = options

		Sentry.captureEvent({
			...options,
			message, // 에러 메시지
		})
	}

	return {
		setEvent,
	}
}
export default useSentry
