import { useEffect } from 'react'
import { Box } from '@mui/joy'
import UIContainer from '@components/ui/UIContainer'

interface HouseBannerWrapperProps {
	/** 배경 색상 */
	bgcolor?: string
	/** view port 에 진입했을때 실행될 함수 */
	onTrackImpression: () => void

	children?: React.ReactNode
}

const HouseBannerWrapper = ({ children, bgcolor, onTrackImpression }: HouseBannerWrapperProps) => {
	useEffect(() => {
		onTrackImpression()

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<UIContainer sx={{ overflow: 'hidden' }}>
			<Box
				sx={{
					backgroundColor: bgcolor,
					overflow: 'hidden',
					img: {
						display: 'block',
						marginLeft: '50%',
						transform: 'translateX(-50%)',
					},
				}}
			>
				{children}
			</Box>
		</UIContainer>
	)
}
export default HouseBannerWrapper
