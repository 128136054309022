import { CONTAINER_IDS } from '@services/api/Ads/constants'
import { useQueryAds } from '@services/api/Ads'
import { useMediaQuery } from '@hooks/useMediaQuery'
import { theme } from '@ui/style'
import { HouseBannerWrapper } from '@components/ads/Home/HouseBanner'

const HouseBanner = () => {
	const isMobile = useMediaQuery(
		`only screen and (min-width: ${theme.breakpoint.sm}px) and (max-width: ${theme.breakpoint.origin}px)`,
	)
	const { data, isNoneAds } = useQueryAds({ type: CONTAINER_IDS.houseBanner, isMobile })

	if (isNoneAds) {
		return null
	}

	const {
		width,
		height,
		bgcolor,
		image_file: src = '',
		click_through: clickThrough,
		click_tracking: clickTracking,
		impression_tracking: impressionTracking,
	} = data || {}

	/** Nitmus 광고 데이터 Tracking 하기위한 함수  */
	const getTrackData = (url: string) => {
		const img = new Image()
		img.src = url
	}

	/** Image Banner 클릭 시 */
	const onClick = () => {
		if (!clickThrough) {
			return
		}

		/** Tracking 주소가 있다면 실행 */
		if (clickTracking) {
			getTrackData(clickTracking)
		}

		window.location.href = clickThrough
	}

	/** Impression : 첫 호출시 실행 */
	const onTrackImpression = () => {
		if (!impressionTracking) {
			return
		}

		getTrackData(impressionTracking)
	}

	return (
		<HouseBannerWrapper bgcolor={bgcolor} onTrackImpression={onTrackImpression}>
			<img
				src={src}
				alt="house-banner-image"
				width={width}
				height={height}
				onClick={onClick}
			/>
		</HouseBannerWrapper>
	)
}
export default HouseBanner
