import isExternalUrl from '@utils/http/isExternalUrl'
import { convertFetchURL, getBaseURL } from '@utils/http/index'

type FetchRequestType = {
	path: string
	method?: 'GET' | 'POST'
	params?: URLSearchParams
	cookieHeader?: string
	body?: BodyInit
}

type FetchResponseType<T> = Promise<T>

const fetchAPI = async <T>({
	path,
	method = 'GET',
	params,
	cookieHeader,
	body,
}: FetchRequestType): FetchResponseType<T> => {
	const isExternal = isExternalUrl(path)
	const url = isExternal
		? `${path}${params ? `?${params.toString()}` : ''}`
		: `${getBaseURL()}${path}${params ? `?${params.toString()}` : ''}`

	const headers = new Headers({
		Accept: 'application/json',
		Origin: process.env.NEXT_PUBLIC_SITE_URL,
		...(cookieHeader && { Cookie: cookieHeader }),
	})

	try {
		const response = await fetch(convertFetchURL(url), {
			method,
			headers,
			body: body instanceof FormData ? body : JSON.stringify(body),
			cache: 'no-cache',
			credentials: 'include',
		})

		return await response.json()
	} catch (error) {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-expect-error
		return { error }
	}
}

export const fetchGet = <T>(options: FetchRequestType) => fetchAPI<T>({ method: 'GET', ...options })
