import { fetchGet } from '@utils/http/fetchLib'

interface GetAdsProps {
	containerId: string
	jwtToken: string
}

type GetAdsResponseType = {
	/** 배너 이미지 url */
	image_file: string
	/** 배너 클릭 주소 */
	click_through: string
	/** 배너 클릭 이벤트 발생 시 호출 url */
	click_tracking: string
	/** 배너 노출 이벤트 발생 시 호출 url */
	impression_tracking: string
	/** 배너 가로 길이 */
	width: number
	/** 배너 세로 길이 */
	height: number
	/** 배경색 코드 */
	bgcolor: string
	/** 대체 텍스트 */
	alternative_text?: string
	/** 에러 시 */
	error?: 'none ad' | 'error' | string
}

const url = 'https://cast-imp.jtbc.co.kr/bid'

const getAds = ({ containerId, jwtToken }: GetAdsProps) => {
	const path = `${url}/${containerId}/${jwtToken}`

	return fetchGet<GetAdsResponseType>({ path })
}
export default getAds
